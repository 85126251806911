import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../icons/GTechnology-Logo.jpg';
import india from '../icons/india-flag.png';
import australia from '../icons/australia-flag.webp';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary navbar-styles">
        <div className="container-fluid">
            <Link to="/" className="navbar-brand">
                <img className="madas-health-logo" src={logo} alt="MediSpeak Logo" />
                <span className="logo-label">MadasHealth.AI</span>                
            </Link>

            <button 
                className="navbar-toggler" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/about-us">About Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/healthprofessionals">Health professionals</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link custom-nav-link" to="/patients">Patients</Link>
                    </li>
                    <li className="nav-item">
                          <Link className="nav-link custom-nav-link" to="/others">AI Consultancy</Link>
                    </li>
                    <li className="nav-item">
                          <Link className="nav-link custom-nav-link" to="/collaborations">AI Training</Link>
                    </li>
                     <li className="nav-item d-flex justify-content-end">
                        <a href="https://madashealthai.in/"><img className="madas-health-logo-flag" src={india} alt="MediSpeak Logo" /></a>
                        <a href="https://madashealth.ai/"><img className="madas-health-logo-flag" src={australia} alt="MediSpeak Logo" /></a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default Navbar;
