import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import meetSmart from '../icons/meet2.webp'

function InternsPresentPastMeetSmartTwo() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
  };

  return (
    <div className="testimonials">
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">            
            <div className="text-block-spec">                          
                      <ul><h5><strong> Healthcare-Specific AI Consultancy Specialists</strong></h5>
                          
                          At Madas Health AI, we help countries build customized AI solutions to address their unique healthcare needs.
                          Led by Dr. Eshwar Madas, and supported by Kanwaljit Singh Kareer, we specialize in creating healthcare-specific AI frameworks that solve real challenges and deliver impactful results.
                <br></br><br></br>
                          <h6><strong>Our consultancy services are designed to maximize the value of AI in healthcare. Here's how we can help</strong></h6>
                          <li>Custom AI Solutions for Your Country's Needs: We understand that every country faces different healthcare challenges. Thats why we tailor AI solutions to address the specific needs of your healthcare system.</li>
                          <li>Prioritizing the Right AI Technologies: We help you identify and prioritize the AI technologies that will have the greatest impact on your healthcare services.</li>
                          <li>AI for All Levels of Healthcare: From primary care to specialized tertiary care, we develop AI technologies that fit seamlessly into every level of healthcare.</li>
                          <li>Crafting AI Vision and Goals: We work closely with governments to create an AI vision and mission that aligns with your national healthcare goals, shaping the future of health services.</li>
                          <li>Monitoring and Ensuring Success: Our AI frameworks include monitoring systems to make sure initiatives stay effective and scalable.</li>
                          <li>Fraud Detection in Healthcare: We use AI to proactively detect and prevent fraud in healthcare services, insurance, hospitals, and aged care.</li>
                          <li>Enhancing Medical Education: Our AI solutions improve medical and health education, preparing healthcare professionals for the future.</li>
                          <li>Supporting Healthcare Policy Development: We assist in developing AI-powered healthcare policies that improve service delivery, resource management, and patient care.</li>
                          <li>Boosting Efficiency: Our AI tools streamline hospital and clinic workflows, reducing administrative work and enhancing overall efficiency.</li>
                          <li>Predictive Analytics for Better Health: We analyze population health data using AI to predict health outcomes, improve planning, and drive proactive interventions.</li>
                          <li>Expanding Access with AI-Powered Telehealth: We help integrate AI into telehealth services, making healthcare accessible to remote and underserved areas.</li>
                          <li>Secure Healthcare Data Management: We provide guidance on managing healthcare data with AI, ensuring it is secure, ethical, and well-utilized.</li>

                <br></br><br></br>                
                          At Madas Health AI, we are committed to transforming healthcare with innovative AI solutions that adapt to your needs and make a real difference.
              </ul>
            </div>
            <div className="logo-block-spec"><img className="gridlogo-spec" src={meetSmart} alt="Document Accuracy"></img></div>
          </div>          
        </div>               
    </div>
  );
}

export default InternsPresentPastMeetSmartTwo;
